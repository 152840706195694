<template>
  <Column :class="$viewport.desktop ? 'grid-8' : ''"
          :full="$viewport.mobile">
    <Row align="center"
         justify="between">
      <label class="guest-reviews__label"
             for="review">Описание</label>
      <span class="guest-reviews__textarea-counter">
                    Осталось {{textareaCount}} символов</span>
    </Row>
    <textarea class="guest-reviews__input"
              id="review"
              v-model="comment"/>
    <div>
      <Button v-on:click.native="setComment"
              :disabled="!comment.length > 0"
              color="green">Отправить
      </Button>
    </div>
  </Column>
</template>

<script>
export default {
  name: 'LeaveComment',
  props: {
    params: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      comment: '',
      textareaMaxCount: 700,
    };
  },
  computed: {
    textareaCount() {
      return this.textareaMaxCount - this.comment.length;
    },
  },
  methods: {
    setComment() {
      let string = '?';
      string += `parent_id=${this.params.parent_id}`;
      string += `&comment=${this.comment}`;
      this.$http.post(`/api/${this.$page.type}/${this.$route.params.id}/comments${string}`);
    },
  },
};
</script>
